import React from 'react';
import Logo from './logowhite.png';
import styles from './style.module.css';
import { Loading } from '../../../Components';
import { AiFillLock } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import { useState } from 'react';
import ReactGA from 'react-ga';

const Login = ({
  form,
  handleSubmit,
  handleChange,
  error,
  loading,
}) => {

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    ReactGA.event({
      category: 'Botão',
      action: 'Clique',
      label: 'Visualizar senha'
    });
    if(passwordType === "password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }
  return (
    <Loading show={loading}>
      <div className={styles.loginContainer}>
        <div className={styles.left}>
          <img src={Logo} alt="Logo" />
        </div>
        <div className={styles.right}>
          <div className={styles.cardForm}>
            <div className={styles.cardFormHeader}>
              <h4 className={styles.textInfo}>Entrar</h4>
              <p> Insira seu usuário e senha para acessar. </p>
            </div>

            <div className={styles.inputsContainer}>
              <div className={styles.formGroup}>
                <label className={styles.formItem}>
                  E-mail *
                  <input
                    onChange={handleChange}
                    placeholder="Digite o e-mail" 
                    type="email"
                    value={form.email}
                    name="email"
                  />
                </label>
              </div>
              <div className={styles.formGroup}>
                <label className={styles.formItem}>
                  <button className={styles.passType}>
                    { passwordType === "password" ?
                      <i onClick={togglePassword}>
                        <AiFillEyeInvisible />
                      </i> :
                      <i onClick={togglePassword}>
                      <AiFillEye />
                      </i>
                    }
                  </button>
                  Senha *
                  <div className={styles.passContainer}>
                    <input 
                      onChange={handleChange} 
                      placeholder="Digite a senha" 
                      type={passwordType}
                      value={form.password} 
                      name="password"
                    />                  
                  </div>
                </label>            

              </div>

            </div>
            <button onClick={handleSubmit} className={styles.btnSubmit}>Entrar</button>
            <div className={styles.errorMessage}>
              <small>{error}</small>
            </div>
          </div>
        </div>
      </div>
    </Loading>
  )
}

export default Login
