import { useRef, useState } from 'react';
import { uploadFile, getPastDueByContract } from '../../services/kollectaApi';
import styles from './style.module.css';
import Icon from './../../assets/images/iconnewDark.svg';
import Modal from '../Modal';
import ReactLoading from 'react-loading';
import success from '../../assets/images/dataSuccess.svg';
import error from '../../assets/images/error.svg';
import warning from '../../assets/images/warningOrange.svg';
import * as xlsx from 'xlsx';
import { formatToBRL } from 'brazilian-values'

const UploadFileAndList = ({ showList, dataForm, setDataFormAfterUse }) => {
  const filesElement = useRef(null);
  const [files, setFiles] = useState([]);
  const [popUpFeedBack, setPopUpFeedBack] = useState({
    loading: false,
    datesImported: false,
    success: false,
    apiError: false,
    contractError: false,
    downloadError: false,
    typeError: false,
    totalImported: 0,
    total: 0
  });

  const parserCurrencyValue = (value = '') => {
    if (value === null) {
      value = 0;
    }
    if (typeof value === 'object' && value.result) {
      value = value.result;
    }
  
    let num;
    if (typeof value !== 'string') {
      num = parseFloat(value);
    } else {
      num = parseFloat(value.replace(/[^\d,.-]+/g, '').replace(',', '.'));
    }
  
    return Math.round(num * 100);
  }

  const verifyFile = async () => {
    setPopUpFeedBack({ ...popUpFeedBack, loading: true });

    try {
        const dataForm = new FormData();
        for (const file of filesElement.current.files) {
            setFiles([...files, file]);
            dataForm.append('file', file);
        }

        let totalLines = 0;
        let totalValue = 0;
        const file = filesElement.current.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const sheetData = xlsx.utils.sheet_to_json(worksheet, { header: 1 });
          const valueIndex = sheetData[0].indexOf("valor_corrigido");
          const contractIndex = sheetData[0].indexOf("contrato");
          sheetData.shift();

          const filteredSheetData = sheetData.filter(row => row.some(cell => cell !== undefined && cell !== ''));

          const responses = [];

          for (let row of filteredSheetData) {
            const contractValue = row[contractIndex];
            const response = await getPastDueByContract(contractValue);
            
            if (response.data) {
              responses.push({ contractValue, error: true });
              setPopUpFeedBack({ ...popUpFeedBack, success: false, loading: false, typeError: false, confirm: false, contractError: contractValue });
              return;
            } else {
              responses.push({ contractValue, error: false });
            }
          }

          for (let resp of responses) {
              if (resp.error) {
                  setPopUpFeedBack({ ...popUpFeedBack, success: false, loading: false, typeError: false, confirm: false, contractError: resp.contractValue });
              }
          }


          totalLines = filteredSheetData.length;
          totalValue = filteredSheetData.reduce((total, row) => {
              const value = typeof row[valueIndex] === 'string'
                  ? parseFloat(row[valueIndex].replace('R$', '').replace(',', '.'))
                  : row[valueIndex];
              return isNaN(value) ? total : total + value;
          }, 0);

          setPopUpFeedBack({
              ...popUpFeedBack,
              success: false,
              loading: false,
              confirm: true,
              totalImported: totalLines,
              totalValue: totalValue,
              dataForm: dataForm,
          });
        };

        reader.readAsBinaryString(file);

      } catch (error) {
        console.error(error);
        setPopUpFeedBack({
          ...popUpFeedBack,
          success: false,
          loading: false,
          typeError: true,
          message: error.message,  });
      }
  }



  const sendFile = async (dataForm) => {
    try {
      setPopUpFeedBack({ ...popUpFeedBack, loading: true, confirm: false })
      const res = await uploadFile(dataForm)
      setPopUpFeedBack({ ...popUpFeedBack, success: true, loading: false, confirm: false })
    } catch (error) {
      setPopUpFeedBack({ ...popUpFeedBack, success: false, loading: false, typeError: true, confirm: false });
    }
  }


  const handlePostWallet = async () => {
    try {
      for (const file of filesElement.current.files) {
        setFiles([...files, file])

        dataForm.append('file', file)
        setDataFormAfterUse(dataForm)
      }

    } catch (error) {
      alert(`Não foi possível importar o arquivo!`)
    }
  }


  return (
    <div className={styles.uploadFileContainer}>
      <div className={styles.contentInputFile}>
        <div class={styles.inputFileUploadContainer}>
          <h1> Faça o Upload do seu arquivo </h1>
          <input className={styles.inputFile} id="my-file" type="file" accept=".csv, .xlsx" onChange={showList ? verifyFile : handlePostWallet} ref={filesElement} />
          <label tabindex="0" for="my-file" className={styles.inputFileTrigger}>
            <span className={styles.triggerIcon}><img className={styles.inputIcon} src={Icon} alt="upload files" /></span>
            Arraste seu arquivo Excel aqui
            <h5>ou</h5>
            <label tabindex="0" for="my-file" className={styles.btnUpload}>
              Buscar arquivo
            </label>
          </label>
        </div>
      </div>


      <div className={styles.list}>
        <h1> Arquivos recebidos </h1>
        <div className={styles.listContainer}>
          {showList && (
            <div className={styles.fileItens}>
                {files.map((file, index) => (
                  <div className={styles.fileItem}>
                    <div key={index}>
                      <h3 className={styles.itemNameFile}>{file.name}</h3>
                    </div>
                  </div>
                ))}
              </div>
            )}
        </div>
      </div>

      {/* Modal */}
      {popUpFeedBack.loading && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, loading: !popUpFeedBack.loading })}
          >
            <div className={styles.modalContainer}>
              <p>
                Sua análise está em processamento e pode demorar alguns minutos para ser concluída
              </p>
              <ReactLoading type={'spin'} color={'#293053'} height={50} width={50}/>
            </div>
          </Modal>
        )}

      {popUpFeedBack.success && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, success: !popUpFeedBack.success })}
          >
            <div className={styles.modalContainer}>
              <img src={success} alt="success" />
              <p>
                Carteira importada <b> com sucesso </b>! Agora <br />
                começa o nosso trabalho para acionarmos <br />
                os seus <b> clientes devedores </b>
              </p>
            </div>
          </Modal>
        )} 

         {popUpFeedBack.confirm && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, success: false, confirm: false, })}
          >
            <div className={styles.modalContainer}>
              <img src={warning} alt="warning" />
              <p>
                Você está prestes a cadastrar uma carteira <br />
                contendo <b>{popUpFeedBack.totalImported} devedores</b>. o Valor total da <br />
                carteira é de <b>{formatToBRL(popUpFeedBack.totalValue)}</b>.
              </p>

              <button className={styles.btnSubmit} 
              onClick={() => sendFile(popUpFeedBack.dataForm)}>
                Estou de acordo e quero cadastrar
              </button>
            </div>
          </Modal>
        )}   


        {popUpFeedBack.typeError && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, apiError: false })}
          >
            <div className={styles.modalContainer}>
              <img src={error} alt="warning" />

              <p>
                Não foi possível importar o arquivo!
                Código do  erro: KC-001
              </p>

              <button className={styles.btnSubmit} onClick={() => window.location.reload()}>Fechar</button>
            </div>
          </Modal>
        )}

        {popUpFeedBack.contractError && (
          <Modal
            onClose={() => setPopUpFeedBack({ ...popUpFeedBack, contractError: false })}
          >
            <div className={styles.modalContainer}>
              <img src={error} alt="warning" />

              <p>
                Contrato {popUpFeedBack.contractError} já existe!
                Código do  erro: KC-010
              </p>

              <button className={styles.btnSubmit} onClick={() => window.location.reload()}>Fechar</button>
            </div>
          </Modal>
        )}


    </div>
  )
}

export default UploadFileAndList
