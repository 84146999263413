import React, { useState, useEffect } from 'react';
import styles from './style.module.css';
import { parseCurrency } from '../../../utils/currency';
import LineChartComponent from '../../../Components/Charts/line';
import PieChartComponent from '../../../Components/Charts/pie';
import BarChartComponent from '../../../Components/Charts/bar';
import { TbCurrencyDollar } from 'react-icons/tb';
import Select from '../../../Components/Select';
import { getUniqueCompanyDisplayNames, getPastDuesMeasurements } from '../../../services/kollectaApi';
import LoadingOverlay from '../../../Components/LoadingOverlay';
import DateFilter from '../../../Components/DateFilter';
import { Button } from '../../../Components';
import Modal from 'react-modal';
import ReportFilterTool from '../../../Components/ReportFilterTool';
import ModalStatus from '../../../Components/ReportFilterTool/ModalStatus';
import HeaderInternal from '../../../Components/HeaderInternal';

import brokenAgreementsIcon from './brokenAgreementsIcon.svg';
import conversationIcon from './conversationIcon.svg';
import cpcIcon from './cpcIcon.svg';
import emmitedAgreementsIcon from './emittedAgreementsIcon.svg';
import paidAgreementsIcon from './paidAgreementsIcon.svg';
import paymentPromiseIcon from './paymentPromiseIcon.svg';


const Home = () => {
  const [creditors, setCreditors] = useState('');
  const [companyDisplayName, setCompanyDisplayName] = useState('all');
  const [pastDuesMeasurements, setPastDuesMeasurements] = useState();
  const [loading, setLoading] = useState(true);
  const [dateRangeFilter, setDateRangeFilter] = useState({ startDate: null, endDate: null });
  const [modal, setModal] = useState(false);
  const [modalFeedback, setModalFeedback] = useState(false);

  const handleDateRangeChange = (range) => {
    setDateRangeFilter(range);
  };

  const handleSelectChange = (event) => {
    setCompanyDisplayName(event.target.value);
  };

  const getPastDuesMeasurementsData = async () => {
    const data = {
      companyDisplayName,
      startDate: dateRangeFilter.startDate,
      endDate: dateRangeFilter.endDate,
    };
    const pastDues = await getPastDuesMeasurements(data);
    if (pastDues?.data?.data) {
      setPastDuesMeasurements(pastDues.data.data);
    }
    setLoading(false);
  };

  const getCreditors = async () => {
    const creditors = await getUniqueCompanyDisplayNames();
    if (creditors?.data?.creditors) {
      const options = [
        { value: 'all', label: 'Todos credores' },
        ...creditors.data.creditors.map(item => ({ value: item.value, label: item.label })),
      ];
      setCreditors(options);
    }
  };

  const closeFiltersModal = () => {
    setModal(false);
  };

  const closeStatusModal = () => {
    setModal(false);
    setModalFeedback(false);
  };

  useEffect(() => {
    getCreditors();
  }, []);

  useEffect(() => {
    getPastDuesMeasurementsData();
  }, [companyDisplayName, dateRangeFilter]);

  return (
    <div className={styles.wrapper}>
      <LoadingOverlay isVisible={loading} />

      <HeaderInternal title="Gestão de carteira" bold={true} />

      <div className={styles.content}>
        <div className={styles['action-buttons']}>
          {/* {creditors && (
            <Select 
              options={creditors}
              value={companyDisplayName}
              onChange={handleSelectChange}
              className={styles.secondary}
            />
          )} */}
          <Button onClick={() => setModal(true)} className={styles['primary']}>Exportar Relatório</Button>
        </div>

        <div className={styles['tab-wrapper']}>
          <div className={styles['tab-group']}>
            <div className={styles['tab-item']}>
              <span>Desempenho da estratégia</span>
            </div>
          </div>

          <main>
            <div className={styles['highlight-cards']}>
              <div className={styles['highlight-card']}>
                <img src={conversationIcon} alt="Conversas realizadas" />
                <div className={styles['highlight-card-info']}>
                  <span>Conversas realizadas</span>
                  <strong>{pastDuesMeasurements?.botsData?.uniquePersonalNumbers?.count || '-'}</strong>
                </div>
              </div>

              <div className={styles['highlight-card']}>
                <img src={cpcIcon} alt="CPC" />
                <div className={styles['highlight-card-info']}>
                  <span>CPC</span>
                  <strong>{pastDuesMeasurements?.countCpc || '-'}</strong>
                </div>
              </div>

              <div className={styles['highlight-card']}>
                <img src={paymentPromiseIcon} alt="Promessa de pagamento" />
                <div className={styles['highlight-card-info']}>
                  <span>Promessa de pagamento</span>
                  <strong>{parseCurrency(pastDuesMeasurements?.totalPromiseToPay) || '-'}</strong>
                </div>
              </div>

              <div className={styles['highlight-card']}>
                   <img src={emmitedAgreementsIcon} alt="Acordos emitidos" />
                <div className={styles['highlight-card-info']}>
                  <span>Títulos em cobrança</span>
                  <strong>{pastDuesMeasurements?.count || '-'}</strong>
                </div>
              </div>

              <div className={styles['highlight-card']}>
                   <img src={emmitedAgreementsIcon} alt="Acordos emitidos" />
                <div className={styles['highlight-card-info']}>
                  <span>Volume total da carteira</span>
                  <strong>{parseCurrency(pastDuesMeasurements?.total) || "R$ -"}</strong>
                </div>
              </div>

              <div className={styles['highlight-card']}>
                   <img src={emmitedAgreementsIcon} alt="Acordos emitidos" />
                <div className={styles['highlight-card-info']}>
                  <span>Valor recuperado</span>
                  <strong>{parseCurrency(pastDuesMeasurements?.totalPaid) || "R$ -"}</strong>
                </div>
              </div>

              {/* <div className={styles['highlight-card']}>
                <img src={emmitedAgreementsIcon} alt="Acordos emitidos" />
                <div className={styles['highlight-card-info']}>
                  <span>Acordos emitidos</span>
                  <strong>N/A</strong>
                </div>
              </div>

              <div className={styles['highlight-card']}>
                <img src={brokenAgreementsIcon} alt="Acordos quebrados" />
                <div className={styles['highlight-card-info']}>
                  <span>Acordos quebrados</span>
                  <strong>N/A</strong>
                </div>
              </div>

              <div className={styles['highlight-card']}>
                <img src={paidAgreementsIcon} alt="Acordos pagos" />
                <div className={styles['highlight-card-info']}>
                  <span>Acordos pagos</span>
                  <strong>N/A</strong>
                </div>
              </div> */}
            </div>

            <div className={styles.charts}>
              <PieChartComponent />
              {/* <LineChartComponent /> */}
              <BarChartComponent />
            </div>
          </main>
        </div>
      </div>

      {modal && (
        <Modal
          isOpen={modal}
          onRequestClose={closeFiltersModal}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '8px',
              width: '615px',
            },
          }}
        >
          <div className={styles.modalContainer}>
            <ReportFilterTool closeModal={closeFiltersModal} setFeedback={setModalFeedback} />
          </div>
        </Modal>
      )}

      <ModalStatus type={modalFeedback} onClose={closeStatusModal} />
    </div>
  );
};

export default Home;
