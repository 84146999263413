import axios from 'axios';
import axiosInstance from './axiosInstance';

const baseUrl = 'https://api.kollecta.io';
// const baseUrl = 'http://localhost:3003'; // local server address (kollecta-core)

const getOffers = async (personalNumber, companyId) => {
  let url = `${baseUrl}/api/past-dues-unlocked?personalNumber=${personalNumber}`;
  if (companyId) {
    url += `&companyId=${companyId}`;
  }
  return await axios.get(url);
};

const dealOffer = async (offerId, paymentMethod, installmentDay = null, issuedBy = null) => {
  let queryString = `?paymentMethod=${paymentMethod}`;

  if (installmentDay) {
    queryString += `&installmentDay=${installmentDay}`;
  }

  if (issuedBy) {
    queryString += `&issuedBy=${issuedBy}`;
  }

  return await axios.get(`${baseUrl}/api/past-dues-deal/${offerId}${queryString}`);
}

const uploadFile = async (files) => {
  return await axiosInstance.post(`/upload`, files)
}

const auth = async (data) => {
  return await axios.post(`${baseUrl}/auth/login`, data)
}

const getSummaries = async () => {
  return await axiosInstance.get(`${baseUrl}/api/summaries`)
}

const getPastDues = async () => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues`)
}

const getPastDuesById = async (id) => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues/${id}`)
}

const getQueueNext = async () => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-queue-next`)
}

const getByUser = async () => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-by-user`)
}

const getByUserScheduledContact = async () => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-by-status/scheduled_contact`)
}

const resetQueueByUser = async (id) => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-reset-attendance/${id}`)
}


const getSummariesLast30Days = async () => {
  return await axiosInstance.get(`${baseUrl}/api/last-30-days`)
}

const getCurrencyValue = async (id) => {
  return await axiosInstance.get(`${baseUrl}/api/credits/${id}`)
}

const getWallet = async (id) => {
  return await axiosInstance.get(`${baseUrl}/api/wallet/${id}`)
}

const getFilters = async () => {
  return await axiosInstance.get(`${baseUrl}/api/datasets`)
}

const postWallets = async (id, filters, file) => {
  return await axiosInstance.post(`${baseUrl}/api/${id}/wallet?datasets=${filters}`, file)
}

const postWalletsSingle = async (id, filters, document) => {
  return await axiosInstance.post(`${baseUrl}/api/${id}/wallet-single?datasets=${filters}`, { document })
}

const postWalletsConfirm = async (id, companyId, filters, file) => {
  return await axiosInstance.post(`${baseUrl}/api/confirm/${id}/${companyId}/wallet?datasets=${filters}`, file)
}

const postWalletsConfirmSingle = async (id, companyId, filters, document) => {
  return await axiosInstance.post(`${baseUrl}/api/confirm/${id}/${companyId}/wallet-single?datasets=${filters}`, { document })
}
const postCreditsUpdate = async (data) => {
  return await axiosInstance.patch(`${baseUrl}/api/credits`, data)
}

const getCredits = async (id) => {
  return await axiosInstance.get(`${baseUrl}/api/credits/${id}`)
}

const naoSeiDownloadWallet = async (id) => {
  return await axiosInstance.get(`${baseUrl}/api/wallet/download/${id}`)
}

const downloadWalletSingle = async (id) => {
  return await axiosInstance.get(`${baseUrl}/api/wallet-single/download/${id}`)
}

const getCompanySettings = async (siteUrl) => {
  return await axiosInstance.get(`${baseUrl}/api/company-by-site-url?siteUrl=${siteUrl}`)
}

const postSendEmail = async (data) => {
  return await axios.post(`${baseUrl}/api/crm/send-email`, data)
}

const postSendMessage = async (data) => {
  return await axios.post(`${baseUrl}/api/crm/send-message`, data)
}

const updatePastDue = async (id, data) => {
  return await axios.put(`${baseUrl}/api/past-dues/status/${id}`, data)
}

const updateAllPastDueData = async (id, data) => {
  return await axiosInstance.put(`${baseUrl}/api/past-dues/${id}`, data)
}

const getpastDueLogs = async () => {
  return await axiosInstance.get(`${baseUrl}/api/pastdue-logs`)
}

const getpastDueLogsByDocument = async (data) => {
  return await axiosInstance.post(`${baseUrl}/api/pastdue-logs`, data)
}

const createPastdueLog = async (data) => {
  return await axiosInstance.post(`${baseUrl}/api/create-pastdue-logs`, data)
}

const getPastDueByContract = async (contract) => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-contract/${contract}`)
}

const getMonthlyGoals = async (userId) => {
  return await axiosInstance.get(`${baseUrl}/api/users-monthly-goals/${userId}`)
}

const getMonthlyGoalsByMonthly = async (userId, month) => {
  return await axiosInstance.get(`${baseUrl}/api/users-monthly-goals-monthly/${userId}/${month}`)
}

const getByUserStatus = async (status) => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-by-status/${status}`)
}

// contracts
const getPastDuesByDocument = async (personalNumber, companyDisplayName) => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-document?personalNumber=${personalNumber}&companyDisplayName=${companyDisplayName}`)
}

const getTransactionsByDocument = async (personalNumber, companyDisplayName) => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-transactions?personalNumber=${personalNumber}&companyDisplayName=${companyDisplayName}`)
}

// pastDues by Group
const getPastDuesByGroup = async (page) => {
  return await axiosInstance.get(`${baseUrl}/api/past-dues-group-company?page=${page}&limit=5000`)
}

// creditor names
const getUniqueCompanyDisplayNames = async () => {
  return await axiosInstance.get(`${baseUrl}/api/creditors`);
}

// chartPie
const getPieChartData = async (data) => {
  return await axiosInstance.post(`${baseUrl}/api/charts/pie`, data);
}

// chartLine
const getLineChartData = async (data) => {
  return await axiosInstance.post(`${baseUrl}/api/charts/line`, data);
}

// chartLine
const getBarChartData = async (data) => {
  return await axiosInstance.post(`${baseUrl}/api/charts/bar`, data);
}


// measurements pastDues
const getPastDuesMeasurements = async (data) => {
  return await axiosInstance.post(`${baseUrl}/api/past-dues/creditors`, data);
}

// has accountbank
const hasAccountBank = async (id) => {
  return await axiosInstance.get(`${baseUrl}/api/accountbank/${id}`);
}

// usersStats by companyId
const usersStats = async (startMonth, endMonth) => {
  return await axiosInstance.get(`${baseUrl}/api/users-monthly-goals-monthly/stats/${startMonth}/${endMonth}`);
}

// filters
const getFiltersExcel = async (data) => {
  try {
    const response = await axiosInstance({
      url: `${baseUrl}/api/filters/status`,
      method: 'post',
      data: data,
      responseType: 'blob',
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Erro ao buscar o arquivo Excel:', error);
    throw error;
  }
};

// pastDues filters
const filtersPastDues = async (data) => {
  return await axiosInstance.post(`${baseUrl}/api/past-dues-filtered`, data);
}

// operators
const getOperators = async () => {
  return await axiosInstance.get(`${baseUrl}/api/operators`);
}

// create queue
const createQueue = async (data) => {
  return await axiosInstance.post(`${baseUrl}/api/queues`, data);
}

// update queue
const updateQueue = async (queueId) => {
  return await axiosInstance.put(`${baseUrl}/api/queues/${queueId}`);
}

// reset queue
const resetQueue = async (queueId) => {
  return await axiosInstance.get(`${baseUrl}/api/queues/${queueId}`);
}

// validate birthdate
const validateBirthdate = async (data) => {
  return await axiosInstance.post(`${baseUrl}/api/wallet-single/validate-birthday`, data);
}

// contact log
const createContactLog = async (data) => {
  return await axiosInstance.post(`${baseUrl}/api/contact-log`, data);
}

// Portal Access Logs
const getPortalAccessLogs = async () => {
  return await axiosInstance.get(`${baseUrl}/api/access-logs`)
}

const createPortalAccessLogs = async (data) => {
  return await axiosInstance.post(`${baseUrl}/api/access-logs-create`, data)
}

// whatsapp
const getWhatsappHistory = async (bot, cellphone) => {
   return await axiosInstance.get(`${baseUrl}/api/crm/whatsapp-history/bot/${bot}/cellphone/${cellphone}`);
};


export {
  auth, createContactLog, createPastdueLog, createPortalAccessLogs, createQueue, dealOffer, downloadWalletSingle, filtersPastDues, getByUser,
  getByUserScheduledContact, getByUserStatus, getCompanySettings, getCredits, getCurrencyValue, getFilters, getFiltersExcel, getLineChartData, getMonthlyGoals,
  getMonthlyGoalsByMonthly, getOffers, getOperators, getPastDueByContract, getPastDues, getPastDuesByDocument, getPastDuesByGroup, getPastDuesById, getPastDuesMeasurements, getPieChartData, getPortalAccessLogs, getQueueNext, getSummaries,
  getSummariesLast30Days, getTransactionsByDocument, getUniqueCompanyDisplayNames, getWallet, getpastDueLogs,
  getpastDueLogsByDocument, hasAccountBank, naoSeiDownloadWallet, postCreditsUpdate, postSendEmail, postSendMessage, postWallets, postWalletsConfirm,
  postWalletsConfirmSingle, postWalletsSingle, resetQueue, resetQueueByUser, updateAllPastDueData, updatePastDue, updateQueue, uploadFile, usersStats, validateBirthdate, getWhatsappHistory, getBarChartData
};

