import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Card from '../../Card';
import styles from './style.module.css';
import { formatToBRL } from 'brazilian-values';
import { getBarChartData } from '../../../services/kollectaApi';
import MonthRangeSelector from '../MonthRangeSelector';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChartComponent = () => {
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  const [dataChart, setDataChart] = useState([]);
  const [startDate, setStartDate] = useState(threeMonthsAgo); // Data inicial é 3 meses atrás
  const [endDate, setEndDate] = useState(new Date()); // Data final é hoje

  useEffect(() => {
    getData();
  }, [startDate, endDate]);

  const getData = async () => {
    try {
      const data = { startDate, endDate };
      const response = await getBarChartData(data);
      const formattedData = response.data.data; 
      
      console.log('Bar chart data:', formattedData);
      setDataChart(formattedData);
    } catch (error) {
      console.error('Failed to fetch bar chart data:', error);
    }
  };

  const handleDateChange = async (dates) => {
    const { start, end } = dates;
    setStartDate(start);
    setEndDate(end);
    await getData();
  };

  const chartData = {
    labels: dataChart.map(item => item.date), 
    datasets: [
      {
        label: 'CPC',
        data: dataChart.map(item => (item?.CPC + item.Emitidos + item.Pagos + item.Promessas)),
        backgroundColor: '#5D3FD3',
        borderRadius: 10,
      },
      {
        label: 'Promessas',
        data: dataChart.map(item => item.Promessas),
        backgroundColor: '#03B4DB',
        borderRadius: 10,
      },
      {
        label: 'Emitidos',
        data: dataChart.map(item => (item.Emitidos + item.Pagos)),
        backgroundColor: '#FFA800',
        borderRadius: 10,
      },
      {
        label: 'Pagos',
        data: dataChart.map(item => item.Pagos),
        backgroundColor: '#1ABF00',
        borderRadius: 10,
      }
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          usePointStyle: true,
          pointStyle: 'rectRounded',
          padding: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return formatToBRL(tooltipItem.raw / 100);
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        barPercentage: 1,
        categoryPercentage: 0.5,
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            return formatToBRL(value / 100);
          },
          maxTicksLimit: 5,
        },
        grid: {
          display: true,
        }
      }
    },
    layout: {
      padding: {
        top: 30,
      }
    },
    maintainAspectRatio: false,
  };

  return (
    <Card noPadding={true}>
      <section className={styles.wrapper}>
        <div className={styles.topContent}>
          <h1>Eventos por data</h1>
          <MonthRangeSelector onDateChange={handleDateChange} startDate={startDate} endDate={endDate} />
        </div>
        {dataChart.length === 0 ? (
          <p>Nenhum dado disponível para o período especificado. Altere o filtro de datas.</p>
        ) : (
          <div style={{ height: '330px', width: '100%' }}>
            <Bar data={chartData} options={chartOptions} />
          </div>
        )}
      </section>
    </Card>
  );
};

export default BarChartComponent;
