import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import styles from './style.module.css';
import { BiCalendarAlt } from 'react-icons/bi';

import "react-datepicker/dist/react-datepicker.css";

const MonthRangeSelector = ({ onDateChange }) => {
  // Calculando a data de 3 meses atrás
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  // Inicializa o estado com os últimos 3 meses
  const [startDate, setStartDate] = useState(threeMonthsAgo);
  const [endDate, setEndDate] = useState(new Date());

  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (onDateChange && start && end) {
      onDateChange({ start, end });
    }
    if (start && end) {
      setIsOpen(false);
    }
  };

  const toggleOpen = () => setIsOpen(!isOpen);

  const dateDisplay = startDate && endDate
    ? `${startDate.toLocaleDateString('default', { month: 'long', year: 'numeric' })} a ${endDate.toLocaleDateString('default', { month: 'long', year: 'numeric' })}`
    : "Selecione os meses";

  return (
    <div className={styles.wrapper}>
      <button className={styles.dateButton} onClick={toggleOpen}>
        {dateDisplay}
        {/* <BiCalendarAlt /> */}
      </button>

      {isOpen && (
        <div className={styles.datepickerWrapper}>
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
            showMonthYearPicker  // Mostra apenas meses e anos
            dateFormat="MM/yyyy"  // Formato da exibição para mês/ano
          />
        </div>
      )}
    </div>
  );
};

export default MonthRangeSelector;
