import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';

import dashboardIcon from './dashboard.svg';
import homeIcon from './home-icon-silhouette.svg';
import logo from './kollecta.io.png';
import fullLogo from './fullKollecta.svg';
import managementIcon from './management.svg';

import style from './style.module.css';

export function Sidebar() {
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  
  return (
    <nav 
      className={style.navbar} 
      onMouseEnter={() => setIsExpanded(true)} 
      onMouseLeave={() => setIsExpanded(false)}
    >
      <div className={`${style.logo} ${isExpanded ? style['logo-margin'] : ''}`}>
        <img src={isExpanded ? fullLogo : logo} alt="Kollecta.io" />
      </div>
      <ul className={style['navbar-nav']}>
        <li className={`${style['nav-item']} ${location.pathname === '/dashboard/home' ? style.active : ''}`}>
          <Link to="/dashboard/home" className={style['nav-link']}>
            <img src={homeIcon} alt="Home" />
            <span className={style['link-text']}>Home</span>
          </Link>
        </li>
        <li className={`${style['nav-item']} ${location.pathname === '/dashboard/manager-pastdues' ? style.active : ''}`}>
          <Link to="/dashboard/manager-pastdues" className={style['nav-link']}>
            <img src={dashboardIcon} alt="Dashboard" />
            <span className={style['link-text']}>Pesquisa</span>
          </Link>
        </li>
        <li className={`${style['nav-item']} ${location.pathname === '/dashboard/past-due' ? style.active : ''}`}>
          <Link to="/dashboard/past-due" className={style['nav-link']}>
            <img src={managementIcon} alt="Management" />
            <span className={style['link-text']}>Cadastro de Devedores</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}
