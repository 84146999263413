import React from 'react';
import styles from './style.module.css';
import Loggout from '../Loggout';
import image from './profile.png';

const HeaderInternal = ({ title, subtitle, profileImage, bold }) => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.left}>
        {profileImage && (
          <div className={styles.profile}>
            <img src={image} alt="Profile" />
          </div>
        )}
        <div className={styles.data}>

          {bold ? <h2>{title}</h2> : <h3>{title}</h3>}
          <h4>{subtitle}</h4>
        </div>
      </div>

      <div className={styles.right}>
        <Loggout />
      </div>
    </section>
  );
};

export default HeaderInternal;
