import React, { useState } from 'react';
import styles from './style.module.css';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { filtersPastDues, getUniqueCompanyDisplayNames } from '../../../services/kollectaApi';
import { useEffect } from 'react';
import HeaderInternal from '../../../Components/HeaderInternal';
import InputSimple from '../../../Components/InputSimple';
import StatusSelector from '../../../Components/StatusSelector';
import Select from '../../../Components/Select';
import { Button, Table } from '../../../Components';
import formatDocument from '../../../utils/cpf_cnpj';
import parseStatus from '../../../utils/statusParse';
import { parseCurrency } from '../../../utils/currency';
import image from './image.svg';
import LoadingOverlay from '../../../Components/LoadingOverlay';
import Modal from 'react-modal';
import { IoIosClose } from 'react-icons/io';
import CreateQueue from '../../../Components/CreateQueue';

const ManagerPastDues = () => {
  const [user, setUser] = useState({});
  const [credores, setCredores] = useState([]);
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [credor, setCredor] = useState('');
  const [valueFrom, setValueFrom] = useState('');
  const [valueTo, setValueTo] = useState('');
  const [daysFrom, setDaysFrom] = useState('');
  const [daysTo, setDaysTo] = useState('');
  const [pastDues, setPastDues] = useState([]);
  const [totalAmount, setTotalAmount] = useState('');
  const [totalRecords, setTotalRecords] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);

  const handleInputName = (value) => {
    setName(value);
  }

  const handleInputCpf = (value) => {
    setCpf(value);
  }

  const handleInputPhone = (value) => {
    setPhone(value);
  }

  const handleInputValueFrom = (value) => {
    setValueFrom(value);
  }

  const handleInputValueTo = (value) => {
    setValueTo(value);
  }

  const handleInputDaysFrom = (value) => {
    const stringValue = value.toString(); 
    const trimmedValue = stringValue.slice(0, -2); 
    const intValue = parseInt(trimmedValue, 10); 
    setDaysFrom(intValue);
  }
  
  const handleInputDaysTo = (value) => {
    const stringValue = value.toString(); 
    const trimmedValue = stringValue.slice(0, -2); 
    const intValue = parseInt(trimmedValue, 10); 
    setDaysTo(intValue);
  }

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleInputCredor = (event) => {
    setCredor(event.target.value);
  };


  // API
  const getCreditors = async () => {
    const creditors = await getUniqueCompanyDisplayNames();
    if (creditors?.data?.creditors) {
      const options = [
        ...creditors.data.creditors.map(item => ({ value: item.value, label: item.label }))
      ];
      setCredores(options);
    }
    setLoading(false);
  }

  const createObjectAndLog = () => {
    const obj = {};

    if (cpf) obj.personalNumber = cpf;
    if (name) obj.customerName = name;
    if (phone) obj.cellPhone = phone;
    if (selectedStatus) obj.status = selectedStatus;
    if (credor) obj.companyDisplayName = credor;
    if (valueFrom) obj.minValue = valueFrom;
    if (valueTo) obj.maxValue = valueTo;
    if (daysFrom) obj.minDaysPastDue = daysFrom;
    if (daysTo) obj.maxDaysPastDue = daysTo;

    return obj;
  }

  const getData = async () => {
    setLoading(true);

    const body = createObjectAndLog();
    console.log('body', body);

    try {
      const pastDues = await filtersPastDues(body);
      if (pastDues?.data) {
        setErrorMessage('');
        setLoading(false);
        setTotalAmount(pastDues?.data?.totalOriginalAmount);
        setTotalRecords(pastDues?.data?.totalRecords);
        return setPastDues(pastDues?.data?.rows);
      }
      setLoading(false);

    } catch (error) {
      console.log('Error', error);      
      setPastDues([]);
      if (error?.status === 400) {
        setLoading(false);
        return setErrorMessage('Selecione algum filtro válido');
      }

      setLoading(false);
    }

  }

  const tbSettings = [
    { path: 'customerName', center: false, headerName: 'Nome' },
    { path: 'personalNumber', center: false, headerName: 'CPF | CNPJ', columnFormat: formatDocument},
    { path: 'companyDisplayName', center: false, headerName: 'Credor' },
    { path: 'discountAmount', center: false, headerName: 'Valor', columnFormat: parseCurrency },
    { path: 'days', center: false, headerName: 'Atraso' },
    { path: 'status', center: true, headerName: 'Status', columnFormat: parseStatus },
    { path: 'details', center: true, headerName: 'Detalhes' },
  ]

  const closeModal = (item) => {
    setModal(false);
  }
  

  //use Effects
  useEffect(() => {
    getCreditors();
  }, []);  

  useEffect(() => {
  }, [loading]);  

  useEffect(() => {
    const user = localStorage.getItem('user');
    const userParse = JSON.parse(user);
    setUser(userParse);
  }, []);

  useEffect(() => {
  }, [user, name, cpf, phone, selectedStatus, credor, credores, valueFrom, valueTo, daysFrom, daysTo, errorMessage, pastDues, totalAmount, totalRecords, modal]);


	return (
    <div className={styles.searchContainer}>
      <ReactCSSTransitionGroup transitionName="anim"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>

        <LoadingOverlay isVisible={loading}/>

      <div className={styles.top}>
        <HeaderInternal title={user ? user.name : ''} subtitle={user ? user.email : ''} profileImage={true} /> 
      </div> 

        <div className={styles['tab-wrapper']}>
          <div className={styles['tab-group']}>
            <div className={styles['tab-item']}>
              <span>Pesquisa de clientes</span>
            </div>
          </div>

          <main>
          <div className={styles.filtersContainer}>
            <div className={styles.filtersContent}>
             <InputSimple onValueChange={handleInputName} label="Nome" inputType="text" />

             <InputSimple onValueChange={handleInputCpf} label="CPF ou CNPJ" inputType="text" />

             <StatusSelector 
                selectedStatus={selectedStatus}
                handleStatusChange={handleStatusChange}
                label="Status"
              />

             <InputSimple onValueChange={handleInputPhone} label="Telefone" inputType="text" />

            </div>

            <div className={styles.filtersContent}>
              {credores && credores[0] && <Select 
                options={credores}
                value={credor}
                onChange={handleInputCredor}
                label="Credor"
                fullWidth
              />}

              {/* Valor em atraso */}
              <div className={styles.subContainer}>
                <InputSimple onValueChange={handleInputValueFrom} label="Valor em atraso" isCurrency placeholder="De" small />
                <InputSimple onValueChange={handleInputValueTo} isCurrency placeholder="Para" small />
              </div>

              {/* Dias e atraso em dias */}
              <div className={styles.subContainer}>
                <InputSimple onValueChange={handleInputDaysFrom} label="Atraso" placeholder="De" small />
                <InputSimple onValueChange={handleInputDaysTo} placeholder="Para" small />
              </div>

              <div className={styles.generalButton}>
                <Button onClick={getData}>
                  Pesquisar
                </Button>
              </div>

            </div>
          </div>         

          <div className={styles.pastDuesContainer}>
              <h3> Resultado da pesquisa </h3>
              { pastDues.length ? 
                <>
                  <Table data={pastDues} tbSettings={tbSettings} handleButtonClick={setModal} user={user} />
                </> : null
                }

              { pastDues.length === 0 ? (
                <div className={styles.empty}>
                  <h3>Sem resultados</h3>
                  <p>Altere os filtros de pesquisa e clique em "Pesquisar" para gerar resultados</p>
                  <img src={image} alt="Lupa pesquisando no CRM" />

                </div>
              ) : null
              }
            </div>
          </main>
        </div>   

      { modal && 
        <Modal
            isOpen={modal}
            onRequestClose={closeModal}
            style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: '8px',
              width: '615px',
            },
          }}
        > 
          <div className={styles.modalContainer}>
            <button onClick={() => closeModal(false)} className={styles.close}><IoIosClose /></button>
            <CreateQueue data={pastDues} user={user} totalRecords={totalRecords} totalAmount={parseCurrency(totalAmount)} />
          </div>
        </Modal>  
      }
      </ReactCSSTransitionGroup>
    </div>
	)
}

export default ManagerPastDues;
