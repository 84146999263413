import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdLogout } from 'react-icons/md';
import ReactGA from 'react-ga';
import styles from './style.module.css';
import classNames from 'classnames';

const Logout = () => {
  const navigate = useNavigate();

  const loggout = async () => {
    ReactGA.event({
      category: 'Botão',
      action: 'Clique',
      label: 'Sair da conta',
    });

    localStorage.removeItem('token');
    localStorage.removeItem('user.name');
    localStorage.removeItem('user');
    navigate('/login');
  };

  return (
    <div className={styles.logoutContainer}>
      <li
        onClick={loggout}
        className={classNames(styles.menuItem, styles.menuItemLogout)}
      >
        <i className={styles.icon}>
          <MdLogout />
        </i>
        Sair
      </li>
    </div>
  );
};

export default Logout;
