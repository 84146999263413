import React, { useEffect, useRef, useState } from 'react';
import styles from './style.module.css';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { formatDate } from '../../../utils/formatDate';
import ReactGA from 'react-ga';
import { Button } from '../../../Components';
import Breadcrumb from '../../../Components/Breadcrumb';
import parseStatus from '../../../utils/statusParse';
import Timeline from '../../../Components/Timeline';
import { RiPencilFill, RiBarcodeLine } from 'react-icons/ri';
import { IoIosClose } from 'react-icons/io';
import moment from 'moment';
import Modal from 'react-modal';
import Select from '../../../Components/Select';
import TextareaComponent from '../../../Components/Textarea';
import { updateAllPastDueData, createPastdueLog, getpastDueLogsByDocument, getPastDuesById, getPastDues, getPastDuesByDocument, hasAccountBank } from '../../../services/kollectaApi';
import { useDispatch } from 'react-redux';
import { setPastdue } from '../../../Redux/Reducers/PastDue';
import InputSimple from '../../../Components/InputSimple';
import options from '../../../utils/statusArray';
import allStatus from '../../../utils/allStatus';
import CustomRadioButton from '../../../Components/CustomRadioButton';
import { parseCurrency } from '../../../utils/currency';
import Notification from '../../../Components/Notification';
import CustomDatePicker from '../../../Components/DatePicker';
import { useNavigate   } from 'react-router-dom';
import LoadingOverlay from '../../../Components/LoadingOverlay';
import HeaderInternal from '../../../Components/HeaderInternal';
import CustomTimePicker from '../../../Components/TimePicker';
import { combineDateAndTime } from '../../../utils/createDate';
import Contracts from '../../../Components/Contracts';
import CustomerData from '../../../Components/CustomerData';
import IssueInvoice from '../../../Components/IssueInvoice';
import { FaCheck, FaTimes } from 'react-icons/fa';
import WhatsappHistory from '../../../Components/WhatsappHistory';

const PastDueDetails = ({ data }) => {
  const navigate = useNavigate ();
  const [localData, setLocalData] = useState([data]);
  const [user, setUser] = useState({});
  const [statusQueue, setStatusQueue] = useState([]);

  const [status, setStatus] = useState(null);
  const isMounted = useRef(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectValue, setSelectValue] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [updateComponente, setUpdateComponent] = useState(false);
  const [inputOriginalAmount, setInputOriginalAmount] = useState('');
  const [inputDiscountlAmount, setInputDiscountlAmount] = useState('');
  const [entrance, setEntrance] = useState('');
  const [events, setEvents] = useState([]);
  const [showPaymentConditionInputs, setShowPaymentConditionInputs] = useState(false);
  const [showPromiseInputs, setShowPromiseInputs] = useState(false);
  const [showScheduleInputs, setShowScheduleInputs] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [installmentSelected, setInstallmentSelected] = useState({ valor: '', parcelas: '' });

  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');

  const [pastDue, setPastDue] = useState([]);
  const [modal, setModal] = useState(false);
  const [hasAccountbank, setHasAccountbank] = useState(false);
  const [feedbackInvoiceResult, setFeedbackInvoiceResult] = useState('');

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedScheduleDate, setSelectedScheduleDate] = useState(new Date());
  const [activeButton, setActiveButton] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [paths, setPaths] = useState([
    { link: '/#/dashboard/manager-pastdues', label: 'Tela Inicial' },
    { link: '/#/dashboard/details', label: 'Ficha atual' },
  ]);

  const [ totalAmount, setTotalAmount ] = useState([]);


  const dispatch = useDispatch();

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
  }

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const selectedPromisePayment = (label) => {
    setActiveButton(label);
    setShowPromiseInputs(!showPromiseInputs);
    setShowPaymentConditionInputs(false);
    setShowScheduleInputs(false);
  }

  const selectedSchedulePayment = (label) => {
    setActiveButton(label);
    setShowScheduleInputs(!showScheduleInputs);
    setShowPaymentConditionInputs(false);
    setShowPromiseInputs(false);
  }

  const selectedPaymentCondition = (label) => {
    setActiveButton(label);
    setShowPaymentConditionInputs(!showPaymentConditionInputs);
    setShowPromiseInputs(false);
    setShowScheduleInputs(false);
  }

  const handleInputOriginalAmount = (newValue) => {
    setInputOriginalAmount(newValue);
  }

  const handleInputDiscountAmount = (newValue) => {
    setInputDiscountlAmount(newValue);
  }

  const handleEntrance = (newValue) => {
    setEntrance(newValue);
  }

  const handleTextareaValueChange = (newValue) => {
    setTextareaValue(newValue);
  }

  const abrirModal = () => {
    setIsOpenModal(true);
  }

  const fecharModal = () => {
    setIsOpenModal(false);
  }

  const fecharModalInvoice = () => {
    setModal(false);
  }

  const feedbackInvoice = (result) => {
    fecharModalInvoice();
    setFeedbackInvoiceResult(result);
  }


  const translateStatus = (statusValue) => {
    const foundStatus = allStatus.find((item) => item.value === statusValue);
    return foundStatus ? foundStatus.label : statusValue;
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  function parseDate(dateString) {
    const [datePart, timePart] = dateString.split(' - ');
    const [day, month, year] = datePart.split('/');
    const hour = timePart.split('h')[0];
    const minute = timePart.split('h')[1];
    
    return new Date(year, month - 1, day, hour, minute);
  }

  const getQueue = async () => {
    const allPastDues = await getPastDues();
    const queueStatus = localData[0].status;

    const pastDuesByStatus = allPastDues.data.rows.filter((item) => {
      const cleanedStatus = queueStatus.toLowerCase().replace(/[^\w]/g, '');
      const result = item.status.toLowerCase().includes(cleanedStatus);
      if (result) {
        return result;
      }
    });
    setStatusQueue([...statusQueue, pastDuesByStatus]);
  }

  const handleWhatsAppClick = (cellPhone) => {
    ReactGA.event({
      category: 'Botão',
      action: 'Clique',
      label: 'Enviar mensagem no Whatsapp'
    });
    const phoneNumber = cellPhone.replace(/\D/g, '');
    const url = `https://api.whatsapp.com/send?phone=55${phoneNumber}`;
    window.open(url, '_blank');
  }

  const saveEdit = async () => {
    let newStatus;

    const dataToSend = {
      status: selectValue ? selectValue : localData[0].status,
      originalAmount: inputOriginalAmount ? Number(inputOriginalAmount) : undefined,
      discountAmount: inputDiscountlAmount ? Number(inputDiscountlAmount) : undefined,
      entrance: entrance ? Number(entrance) : undefined,
      installments: installmentSelected?.parcelas ? Number(installmentSelected.parcelas) : undefined,
      installmentAmount: installmentSelected?.valor ? Number(installmentSelected?.valor) : undefined,
      user: user.id,
      userDate: new Date(),
      inAttendance: false,
    }

    if (!textareaValue) {
      setShowNotification(true);
      setNotificationType('error');
      setNotificationMessage('Descrição obrigatória!');
      return;
    }
    if (inputOriginalAmount || inputDiscountlAmount || entrance || installmentSelected?.parcelas) {
      newStatus = 'new_condition';
      dataToSend.status = newStatus;
    }   

    await createPastdueLog({
      id: localData[0].id,
      personalNumber: localData[0].personalNumber,
      status: selectValue,
      description: textareaValue,
      changeType: 'manualstatuschange',
      userId:  JSON.parse(localStorage.getItem('user')).id,
      userName: localStorage.getItem('user.name'),
      oldStatus: localData[0].status,
      newStatus: newStatus || dataToSend.status,
    });

    await updateAllPastDueData(localData[0].id, dataToSend).then(async (response) => {
      dispatch(setPastdue(response.data));

      setUpdateComponent(true);
      
      setShowNotification(true);
      setNotificationType('success');
      setNotificationMessage('Edição realizada com sucesso!');

      setLoading(true);
      fecharModal();
      if (user && user.userType === 'operator') {
        return navigate('/dashboard/home-operator');
      }
      return navigate('/dashboard/manager-pastdues');
    }).catch((error) => {
      console.log(error);      
      setShowNotification(true);
      setNotificationType('error');
      setNotificationMessage('Erro ao realizar a edição!');

    });
  }

  const saveSchedule = async () => {
    let newStatus;
    const scheduleDate = getCombinedDateTime();
    if (!scheduleDate) {
      setShowNotification(true);
      setNotificationType('error');
      setNotificationMessage('Não foi possivel criar agendamento, tente novamente');
      return;
    }
    const dataToSend = {
      status: 'scheduled_contact',
      scheduling: scheduleDate,
      user: user.id,
      userDate: new Date(),
      inAttendance: false,
    }

    if (!textareaValue) {
      setShowNotification(true);
      setNotificationType('error');
      setNotificationMessage('Descrição obrigatória!');
      return;
    }
    if (selectedDate) {
      newStatus = 'scheduled_contact';
      dataToSend.status = newStatus;
    }   

    await createPastdueLog({
      id: localData[0].id,
      personalNumber: localData[0].personalNumber,
      status: 'scheduled_contact',
      description: textareaValue,
      changeType: 'scheduled_contact',
      userId:  JSON.parse(localStorage.getItem('user')).id,
      userName: localStorage.getItem('user.name'),
      oldStatus: localData[0].status,
      newStatus: 'scheduled_contact',
      scheduling: scheduleDate,
    });

    await updateAllPastDueData(localData[0].id, dataToSend).then(async (response) => {
      dispatch(setPastdue(response.data))
      localData[0].status = response.data.status;

      setUpdateComponent(true);
      
      setShowNotification(true);
      setNotificationType('success');
      setNotificationMessage('Edição realizada com sucesso!');

      setLoading(true);
      fecharModal();
      if (user && user.userType === 'operator') {
        return navigate('/dashboard/home-operator');
      }
      return navigate('/dashboard/manager-pastdues');
    }).catch((error) => {
      console.log(error);      
      setShowNotification(true);
      setNotificationType('error');
      setNotificationMessage('Erro ao realizar a agendamento!');

    });
  }

  const savePromisePayment = async () => {
    let newStatus;

    const dataToSend = {
      status: selectValue ? selectValue : localData[0].status,
      promisePaymentDate: selectedDate,
      user: user.id,
      userDate: new Date(),
      inAttendance: false,
    }

    if (!textareaValue) {
      setShowNotification(true);
      setNotificationType('error');
      setNotificationMessage('Descrição obrigatória!');
      return;
    }
    if (selectedDate) {
      newStatus = 'promise_to_pay';
      dataToSend.status = newStatus;
    }  
    
    await createPastdueLog({
      id: localData[0].id,
      personalNumber: localData[0].personalNumber,
      status: selectValue,
      description: textareaValue,
      changeType: 'promise_to_pay',
      userId:  JSON.parse(localStorage.getItem('user')).id,
      userName: localStorage.getItem('user.name'),
      oldStatus: localData[0].status,
      newStatus: newStatus || dataToSend.status,
      promisePaymentDate: selectedDate,
    });

    await updateAllPastDueData(localData[0].id, dataToSend).then(async (response) => {
      dispatch(setPastdue(response.data))
      localData[0].status = response.data.status;

      setUpdateComponent(true);
      
      setShowNotification(true);
      setNotificationType('success');
      setNotificationMessage('Edição realizada com sucesso!');

      setLoading(true);
      fecharModal();
      if (user && user.userType === 'operator') {
        return navigate('/dashboard/home-operator');
      }
      return navigate('/dashboard/manager-pastdues');
    }).catch((error) => {
      console.log(error);      
      setShowNotification(true);
      setNotificationType('error');
      setNotificationMessage('Erro ao realizar a edição!');

    });
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDateScheduleChange = (date) => {
    setSelectedScheduleDate(date);
  };

  const getCombinedDateTime = () => {
    return combineDateAndTime(selectedScheduleDate, selectedTime);
  };


  //useEffects

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setLoading(false);
      
      return () => {
        isMounted.current = false;
      }
    }

    fetchData();
  }, [updateComponente, localData[0].personalNumber, localData[0], localData]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    setUser(userData);

    if (userData.userType === 'operator') {
      setPaths([
        { link: '/#/dashboard/home-operator', label: 'Tela Inicial' },
        { link: '/#/dashboard/details', label: 'Ficha atual' },
      ]);
    }
  }, [status, textareaValue, pastDue, updateComponente, selectedDate, localData[0].personalNumber, localData[0], localData]);

  useEffect(() => {
    const fetchData = async () => {
      const accountBank = await hasAccountBank(localData[0].id);
      setHasAccountbank(accountBank?.data?.hasAccountBank || false);
    }
    fetchData();
  }, [localData[0], events, paths]);

  useEffect(() => {
    const options = [];
    let allValue = inputOriginalAmount || pastDue?.originalAmount;
    let allEntrance = entrance || pastDue?.entrance;
    try {
      if (allEntrance) {
        allValue -= allEntrance;
      }

      if (allValue >= 50) {
        options.push({ label: `${parseCurrency(allValue)} x 1 parcela`, value: `${(allValue)}_${1}` });
  
        // Até 36 parcelas
        for (let i = 2; i <= 36; i++) {
          const installmentAmount = (allValue / i).toFixed(2).replace('.', ',');
  
          if (parseFloat(installmentAmount) >= 5000) {
            options.push({ label: `${parseCurrency(parseFloat(installmentAmount))} em ${i} parcelas`, value:`${(allValue / i)}_${i}` });
          } else {
            break;
          }
        }
      }

      setPaymentOptions(options.filter(option => parseFloat(option.value) >= 50));
    } catch (error) {
      console.log(error);
    }

  }, [inputOriginalAmount, pastDue, entrance]);

  const removeSpaces = (string) => {
    return string.replace(/\s+/g, '');
  }

  const getPastDuesByContract = async () => {
    const response = await getPastDuesByDocument(localData[0].personalNumber, removeSpaces(data?.companyDisplayName));

    if (response.data && response.data?.length > 0) {
      const totalAmountPastDues = response.data.reduce((sum, item) => +sum + +item.originalAmount, 0);
      setTotalAmount(totalAmountPastDues);
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      await getQueue();
      await getPastDuesByContract();
    }
    fetchData();

  }, [localData, localData[0], localData[0].personalNumber]);

  useEffect(() => {
  }, [statusQueue]);

  useEffect(() => {
    const fetchStatus = async (personalNumber) => {
      try {
        const response = await getpastDueLogsByDocument({ personalNumber });
        if (!localData && !localData[0]) {
          return console.log('CPF não existe');
        }
        const pastDue = await getPastDuesById(localData[0].id);
        if (isMounted.current && response && response.data) {
          const data = response.data;
          setPastDue(pastDue.data);
          const statusItems = data.filter(item => item.newStatus);
  
          const waitingPaymentStatus = {
            companyId: "",
            createdAt: pastDue.data.createdAt,
            id: 0,
            newStatus: "waiting_payment",
            oldStatus: "",
            personalNumber: personalNumber,
          };
          statusItems.unshift(waitingPaymentStatus);
          
          statusItems.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  
          // Filtra os status repetidos consecutivos
          const filteredStatusItems = statusItems.filter((item, index, arr) => {
            return index === 0 || item.newStatus !== arr[index - 1].newStatus;
          });
  
          setStatus(filteredStatusItems);
  
          const newEvents = filteredStatusItems.map(item => {
            const date = formatDate(item.createdAt);
            let operator = item.userName || 'Sistema';
            let label = 'Alteração de status';
            let description;
            let oldStatus = item.oldStatus;
            let newStatus = item.newStatus;
            
            if(item.changeType === 'manualstatuschange') {
              description = (
                <>
                  {item.description} - Status Origem: {translateStatus(oldStatus)} - Status Destino: <strong>{translateStatus(newStatus)}</strong>
                </>
              );
            } else if (item.changeType === 'botchange') {
              operator = 'Lívia da Kollecta - Chatbot';
              label = 'Alteração de status';
              description = (
                <>
                  Status Origem: {translateStatus(oldStatus)} - Status Destino: <strong>{translateStatus(newStatus)}</strong>
                </>
              );
            } else if (item.id === 0) {
              description = 'Dívida importada';
            } else if (item.newStatus === 'generated') {
              description = (
                <>
                  Boleto gerado no portal - Status Origem: {translateStatus(oldStatus)} - Status Destino: <strong>{translateStatus(newStatus)}</strong>
                </>
              );
            } else if (item.newStatus === 'promise_to_pay') {
              label = 'Promessa de pagamento';
              description = (
                <>
                  {item.description} - Data: {moment(item.promisePaymentDate).format("DD/MM/YYYY")}
                </>
              );
            } else if (item.newStatus === 'scheduled_contact') {
              label = 'Agendamento';
              description = (
                <>
                  {item.description} - Agendado para: {moment(item.scheduling).format("DD/MM/YYYY HH:mm")}
                </>
              );
            } else if (item.newStatus === 'agreement_broken') {
              label = 'Acordo quebrado';
              description = (
                <>
                  Status Origem: {translateStatus(oldStatus)} - Status Destino: <strong>{translateStatus(newStatus)}</strong>
                </>
              );
            } else {
              description = (
                <>
                  Status Origem: {translateStatus(oldStatus)} - Status Destino: <strong>{translateStatus(newStatus)}</strong>
                </>
              );
            }
          
            return { date, operator, label, description, oldStatus, newStatus };
          });
  
          newEvents.sort((a, b) => parseDate(a.date) - parseDate(b.date));
          setEvents(newEvents);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchStatus(localData[0].personalNumber);
  }, [updateComponente, localData[0].personalNumber, localData[0], localData]);
  

  const handlePaymentOptionChange = (event) => {
    const [valor, parcelas] = event.target.value.split('_');

    const selectedOption = {
      valor: valor,
      parcelas: parcelas
    };

    setInstallmentSelected(selectedOption);
  };

  return (
    <div className={styles.container}>
      <LoadingOverlay isVisible={loading}/>
      <ReactCSSTransitionGroup transitionName="anim"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>
        <section>
          <HeaderInternal title={user ? user.name : ''} subtitle={user ? user.email : ''} profileImage={true} />
          <div className={styles.containerTop}>
            <div className={styles.containerContent}>

              <div className={styles.contentTop}>
                <div className={styles.topCardRightContent}>
                  <div className={styles.topCardRightContentTop}>
                    <p>Cliente: {localData[0]?.customerName}</p>
                  </div>

                  <div className={styles.topCardRightContentBottom}>
                    <div className={styles.topCardRightContentBottomLeft}>
                      <div className={styles.currencyContainer}>
                        <span>
                          Valor original do débito
                        </span>
                        <b>{parseCurrency(totalAmount ? totalAmount : localData[0]?.originalAmount)}</b>
                      </div>

                      <div className={`${styles.currencyContainer} ${styles.currencyContainerActive}`}>
                        <span>
                          Valor corrigido do débito
                        </span>
                        <b>{parseCurrency(totalAmount ? totalAmount : localData[0]?.discountAmount)}</b>
                      </div>
                    </div>

                    <div className={styles.topCardRightContentBottomRight}>
                      <div className={styles.rightStatus}>
                        <h5>
                          Status do Acordo
                        </h5>
                        {parseStatus(pastDue?.status ? pastDue?.status : localData[0]?.status) }
                      </div>

                      <div className={styles.rightStatus}>
                        <h5>
                          Status da Mensageria
                        </h5>
                        N/A
                      </div>                  
                    </div>
                                    
                    
                  </div>
                </div>                
              </div>

              <div className={styles.contentBottom}>
                <div className={styles.containerLeft}>
                  <CustomerData customerData={localData[0]} />
                </div>
                <div className={styles.containerTopRight}>
                  <Contracts data={localData[0]} />
                </div>                
              </div>
            </div>
          </div>          

          <div className={styles.containerBottom}>
            <div>
              <h2> Histórico de interações</h2>
              <section className={styles.containerBody}>
                <div className={styles.contentCard}>
                  <div className={styles.contentCardTop}>

                    <div className={styles.buttonsContainer}>

                      {/* Era usado para emitir boleto tecnospeed, mas foi desativado */}
                      {/* {
                        hasAccountbank &&
                          <div className={styles.generalButton}>
                            <Button onClick={() => setModal(true)}>
                              Emitir boleto
                              <RiBarcodeLine width={20} />
                            </Button>
                          </div>
                      } */}
                      
                      <button 
                        type="button" 
                        className={styles.greenButton} 
                        onClick={() => {
                          const phoneNumber = localData[0].cellPhone;
                          const cleaned = ('' + phoneNumber).replace(/\D/g, '');
                          const lastEleven = cleaned.length > 11 ? cleaned.slice(-11) : cleaned;
                          handleWhatsAppClick(lastEleven);
                        }}
                      >
                            Mandar mensagem &nbsp; &gt;
                      </button>

                      <div className={styles.generalButton}>
                        {localData[0].status !== "paid" && localData[0].status !== "indirect_payment" ? (
                          <Button onClick={abrirModal}>
                            Editar
                            <RiPencilFill />
                          </Button>
                        ) : null}
                      </div> 
                    </div>
                  </div>
                </div>             

                <Timeline events={[...events].reverse()} />


              </section>
            </div>

            <div className={styles.contentCard}>
              <div className={styles.whatsappContainer}>
                <h2> Histórico de Conversa</h2>
                <WhatsappHistory bot="livia" cellphone={localData[0].cellPhone} /> 
              </div>
            </div> 
          </div>

        </section>
      </ReactCSSTransitionGroup>         


      <Modal
          isOpen={modal}
          onRequestClose={fecharModalInvoice}
          style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            width: '615px',
          },
        }}
      >
        <div className={styles.modalContainer}>
          <IssueInvoice data={localData[0]} closeModal={fecharModalInvoice} setFeedback={feedbackInvoice}/>
        </div>
      </Modal>  

      <Modal
        isOpen={feedbackInvoiceResult === 'success'}
        onRequestClose={() => setFeedbackInvoiceResult('')}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            width: '615px',
          },
        }}
      >
        <div className={styles.modalContainerFeedback}>
          <FaCheck size={50} color="#fff"/>
          <h4>O boleto foi emitido com sucesso!</h4>
        </div>
      </Modal>

      <Modal
        isOpen={feedbackInvoiceResult === 'error'}
        onRequestClose={() => setFeedbackInvoiceResult('')}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            width: '615px',
          },
        }}
      >
        <div className={styles.modalContainerFeedback}>
          <FaTimes size={50} color="#fff"/>
          <h4>Erro ao emitir boleto! Tente novamente.</h4>
        </div>
      </Modal>

      <Modal
        isOpen={isOpenModal}
        onRequestClose={fecharModal}
        contentLabel="Edition modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            width: '615px',
          },
        }}
      >
        <div className={styles.modalTop}>
          <h4>Edição do histórico da jornada</h4>
          <button onClick={fecharModal} className={styles.close}><IoIosClose /></button>
        </div>
        <section>
          <div className={styles.contenTop}>
            <h5> Alterar status </h5>
            <Select 
              options={options}
              value={selectValue}
              onChange={handleSelectChange}
            />
          </div>

          <div className={styles.modalInputs}>
            <div className={styles.modalInputsRadios}>
              <CustomRadioButton label="Promessa de pgt." active={activeButton === "Promessa de pgt."} onClickFunction={selectedPromisePayment} />
              <CustomRadioButton label="Agendamento" active={activeButton === "Agendamento"} onClickFunction={selectedSchedulePayment} />
              <CustomRadioButton label="Alterar condição de pgt." active={activeButton === "Alterar condição de pgt."} onClickFunction={selectedPaymentCondition} />
            </div>
            <div className={styles.modalInputsContent}>
              { showPaymentConditionInputs && !showPromiseInputs && !showScheduleInputs && (
                  <>
                    <InputSimple onValueChange={handleInputOriginalAmount} placeholder="Valor da dívida parcelada" isCurrency />

                    <InputSimple onValueChange={handleInputDiscountAmount}  placeholder="Valor da dívida à vista" isCurrency />

                    <InputSimple onValueChange={handleEntrance} placeholder="Entrada" isCurrency />


                    <Select options={paymentOptions} value={`${(installmentSelected.valor)}_${installmentSelected.parcelas}`} onChange={handlePaymentOptionChange} classCustom="inputStyle" />
                  </>
                )}

              { showPromiseInputs && !showPaymentConditionInputs && !showScheduleInputs && (
                <>
                  <CustomDatePicker onDateChange={handleDateChange} minDateEnabled /> 
                </>
              )}

              { showScheduleInputs && !showPaymentConditionInputs && !showPromiseInputs && (
                <>
                  <div className={styles.dateContainer}>
                    <CustomDatePicker onDateChange={handleDateScheduleChange} minDateEnabled /> 
                  </div>
                  <CustomTimePicker onTimeChange={handleTimeChange} />
                </>
              )}
            </div>
          
          </div>

          <div className={styles.textarea}>
            <TextareaComponent onValueChange={handleTextareaValueChange} />
          </div>

          <div className={styles.containerButtons}>
            <Button onClick={fecharModal} >
              Cancelar
            </Button>

          { showPromiseInputs &&  
            <Button onClick={savePromisePayment} >
              Salvar edição
            </Button>
          }

          { showScheduleInputs &&  
            <Button onClick={saveSchedule} >
              Salvar edição
            </Button>
          }

          { !showPromiseInputs && !showScheduleInputs &&
            <Button onClick={saveEdit} >
              Salvar edição
            </Button>
          }
          </div>
        </section>
      </Modal>
      
      {showNotification && 
        <Notification  message={notificationMessage} onClose={handleCloseNotification} type={notificationType} />      
      }  
    </div>
  )
}

export default PastDueDetails;
